<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>惠豆商城</el-breadcrumb-item>
          <el-breadcrumb-item>商品管理</el-breadcrumb-item>
          <el-breadcrumb-item>分类列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                @click="setDialog({ hierarchy: 1, parentId: 0 }, 'first')"
                icon="el-icon-plus"
                >新增
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table :data="list" @selection-change="handleSelectionChange">
          <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
          <el-table-column label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="beanMin" label="最低惠豆"></el-table-column>
          <el-table-column prop="beanMax" label="最高惠豆"></el-table-column>
          <!--                    <el-table-column prop="sort" label="排序"></el-table-column>-->
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="setDialog(scope.row, 'edit')"
                >编辑
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
        <add
          :row="infoDetail"
          @dialog="getDialog"
          v-if="dialogAddVisible"
        ></add>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/components/admin/mall/query/add.vue";

export default {
  components: {
    add,
  },
  props: {},
  data() {
    return {
      list: [],
      selectId: "",
      dialogAddVisible: false,
      dialogChildrenVisible: false,
      infoDetail: {},
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      goods_verify_count: 0,
      where: {
        goods_name: "",
        limit: 0,
        page: 1,
        parentId: 0,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getList() {
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.beansectioListByPage, this.where).then((res) => {
        this.totalData = res.data.count;
        this.list = res.data.data;
        this.list.forEach((rs, index) => {
          this.list[index].hasChildren = true;
        });
      });
    },
    // 删除处理
    del(id) {
      if (this.$isEmpty(id)) {
        return this.$message.error("请先选择删除的对象");
      }
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postFormData(this.$api.beansectioDel, { id: id }).then(
            (res) => {
              if (res.code == 1) {
                this.getList();
                this.$router.go(0);
                this.$message.success("删除成功");
              } else {
                this.$message.error("删除失败");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    currentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    getDialog(val) {
      this.dialogAddVisible = val;
      this.dialogChildrenVisible = val;
    },
    setDialog(val, type) {
      this.infoDetail = val;
      this.infoDetail.type = type;
      this.dialogAddVisible = true;
    },
  },
  created() {
    this.getList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right {
//   /*width: 14%;*/
// }

.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}

.el-upload-dragger {
  height: 150px;
}
</style>
